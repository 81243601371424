import React from 'react'
import Icon from 'react-fa'
import Carousel from 'nuka-carousel'

export class testimonialsComponent extends React.Component {
    render() {
        return (
            <section className="testimonials-section section">
                <h2 className="section-title">Featured Reviews</h2>

                <div id="testimonials-carousel" style={{maxHeight: "350px"}} className="testimonials-carousel carousel slide">
                    <Carousel autoplay={true} autoplayInterval={5000}>
                        <div className="item">
                            <blockquote className="quote">
                                <p><Icon name="quote-left"/>MacDonnell incorporates humor through a fresh perspective of a dog’s life with their charming perspectives of humans.</p>
                            </blockquote>
                            <div className="source">
                                <div className="name">Elizabeth Konkel, Seattle Book Review</div>
                                <div className="position">The Way of The Dog</div>
                            </div>
                        </div>
                      <div className="item active">
                        <blockquote className="quote">
                          <Icon name="quote-left"/>
                          <p>The images the novel creates of the puppy learning about his existence will bring a smile to the reader’s face.</p>
                        </blockquote>
                        <div className="source">
                          <div className="name">Jennifer Weiss, Pacific Book Review</div>
                          <div className="position">The Way of the Dog</div>
                        </div>
                      </div>
                        <div className="item active">
                            <blockquote className="quote">
                                <Icon name="quote-left"/>
                                <p>MacDonnell's prose moves beyond her subject and its story to become an experience for the reader.</p>
                            </blockquote>
                            <div className="source">
                                <div className="name">Daniel Casey, Tulsa Book Review</div>
                                <div className="position">The Way of the Dog</div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </section>
        );
    }
}
export default testimonialsComponent;