import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    root: {
      width: '40px'
    },
    chip: {
      fontSize: '15px',
      margin: theme.spacing.unit,
    },
    icon:{
        width: '40px',
        fontSize: '15px',
    },
    avatarChild: {
      width: '12px'
    }
});

class BasicChip extends React.Component{
  render(){
    const { classes, label, avatar} = this.props;
    return (
      <span>
        {avatar && <Chip label={label} className={classes.chip} classes={{avatar: classes.icon, avatarChildren: classes.avatarChild}} avatar={avatar}/>}
        {!avatar && <Chip label={label} className={classes.chip} />}
      </span>
    );
  }
}

BasicChip.propTypes = {
  label: PropTypes.string.isRequired,
  avatar: PropTypes.object,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BasicChip);