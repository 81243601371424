import React from 'react'

import Portfolio from '../Sections/Portfolio'

export class projectComponent extends React.Component {
    render() {
        return (
            <section className="section">
                    <div>
                        <Portfolio/>
                    </div>
            </section>
        );
    }
}
export default projectComponent;