import React from 'react';

export class nameComponent extends React.Component {

    render() {
        if(this.props.type === 'href'){
            return <a href="https://evaamacdonnell.com">Eva A. MacDonnell</a>
        }
        else
        {
            return <span>Eva A. MacDonnell</span>;
        }
    }
}
export default nameComponent;