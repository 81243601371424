import cover from '../img/portfolio/61XaBy8t6uL.jpg'

import React from 'react';
import Typography from '@material-ui/core/Typography';

export const BOOKS = [
    {
        bookId: 1,
        author: 'Eva A. MacDonnell',
        title: 'The Way of the Dog: From The Memoirs of Eros, the Metaphysical Dog ',
        desc: <div>
                <Typography paragraph variant="h6">Eros is a poodle puppy, born in rural New Jersey. He is a prodigy when it comes to questions,
                    although his prodigiousness ends when it comes to answers-for now. As just a puppy, there is much
                    to learn before Eros can enter the wild, a fascinating and dangerous place for dogs and humans alike.
                    He hones his skills for the sole purpose of survival.
                </Typography>
                <Typography paragraph variant="h6">
                    It takes time for young Eros to realize that
                    life is about much more than survival; life is about finding happiness. However, to find happiness,
                    it is imperative that Eros grows stronger, fights better, and finds time to play.
                    There will be difficulties, of course, but the drive to survive inspires endurance in
                    the young pup as he seeks purpose and love.
                </Typography>
                <Typography paragraph variant="h6">
                    When Eros believes happiness is out there waiting,
                    it is! He discovers that finding happiness is not hard if he keeps his eyes open. In this playful
                    allegory, there is much to learn from a poodle puppy, but the wisest thing Eros ever learned:
                    &#34;The purpose of life is to find happiness, and you can only discover happiness through the knowledge
                    that you find.&#34;</Typography>
                </div>,
        kindle: 'https://evaamacdonnell.com',
        bookcicle: 'https://evaamacdonnell.com',
        genre: [ 'Children', 'Metaphysics' ],
        img: cover,
        rating: 'Everyone'
    }
];

export const EMAIL = 'evaamacdonnell@gmail.com';
