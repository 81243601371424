import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BasicChip from './BasicChip'
import Chip from '@material-ui/core/Chip';
import IconFa from 'react-fa/lib/Icon'
import { CheckboxMarkedCircleOutline } from 'mdi-material-ui';
import { mdiAmazon } from '@mdi/js'
import BN from '../img/bn.jpg';
import Icon from '@mdi/react';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        fontSize: '20px'
    },
    chip: {
        fontSize: '15px'
    },
    card: {
    },
    media: {
        height: '100%',
        paddingTop: '150%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    avatarIcon: {
      backgroundColor: '#009688'
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class Book extends React.Component {
    state = { expanded: false };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    getAvatar = (genre, bookId) => {
        switch (genre.toLowerCase()) {
            case 'thriller':
                return <Avatar key={bookId + genre}><IconFa name="bolt"/></Avatar>
            case 'crime':
                return <Avatar key={bookId + genre}><IconFa name="bomb"/></Avatar>
            case 'mystery':
                return <Avatar key={bookId + genre}><IconFa name="automobile"/></Avatar>
            default: return <Avatar><IconFa name="adjust"/></Avatar>
        }
    }

    render() {
        const { classes, book } = this.props;
        let w = window.innerWidth;
        let size = w > 500 ? '50%' : '100%';
        return (
            <div style={{width: size, margin: '0 auto'}}>
            <Card className={classes.card}>
                <CardHeader
                    classes={{title: classes.title}}
                    avatar ={
                        <Chip
                            avatar={
                                <Avatar>
                                    <CheckboxMarkedCircleOutline/>
                                </Avatar>
                            }
                            label={book.rating}
                            className={classes.chip}
                            color="primary"
                        />
                    }
                    action={
                        <Typography variant={"button"}>10/27/2018</Typography>
                    }
                />
                <CardMedia
                    className={classes.media}
                    image={book.img}
                    title="Back Bay Murders"
                />
                <CardContent classes={{root: classes.root}}>
                    {book.genre.map((label, index) =>
                        <BasicChip key={index} label={label} avatar={this.getAvatar(label)}/>
                    )}
                </CardContent>
                <CardContent classes={{root: classes.root}}>
                    <IconButton>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.barnesandnoble.com/w/the-way-of-the-dog-eva-a-macdonnell/1129790615?ean=9781532059124">
                            <img src={BN} alt="B&N" width={40}></img></a>
                    </IconButton>
                    <IconButton>
                        <a rel="noopener noreferrer" href="https://www.amazon.com/Way-Dog-Memoirs-Eros-Metaphysical/dp/1532059124/ref=sr_1_1?s=books&ie=UTF8&qid=1541071488&sr=1-1&keywords=The+Way+of+the+Dog+from+The+Memoirs+of+Eros%2C+the+Metaphysical+Dog" target="_blank">
                            <Icon
                                path={mdiAmazon}
                                size="3em"
                            /></a>
                    </IconButton>
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                    <IconButton
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                    >
                        <Avatar className={classes.avatarIcon}><ExpandMoreIcon /></Avatar>
                    </IconButton>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph variant="h4">
                            Synopsis
                        </Typography>
                        {book.desc}
                    </CardContent>
                </Collapse>
            </Card>
            </div>
        );
    }
}

Book.propTypes = {
    classes: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired
};

export default withStyles(styles)(Book);
