import React from 'react'
import Icon from 'react-fa'
import Button from 'react-bootstrap/lib/Button'

export class rewardsComponent extends React.Component {
  render() {
    return (
      <section className="experiences-section section">
        <h2 className="section-title">Awards and Reviews</h2>
        <div className="timeline">
          <div className="item">
            <div className="work-place">
              <h3 className="place">Christi Lyle, San Francisco Book Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
                "<i>The Way of the Dog from The Memoirs of Eros, the Metaphysical Dog...</i> Was an exceptional tale told through the eyes of the main character, Eros, a dog. Throughout the story,
                (we) are presented with little life lessons, which forces us to recognize many of the emotions that we
                experience in our lives–from happiness and joy to confusion, sadness, and grief…
                Throughout the story, we see that Eros is a dog that is full of questions… Although some of us may not
                outright question as much as he does, most of us, deep down, do have the questions inside us."
              </p>
              <a href="https://sanfranciscobookreview.com/product/the-way-of-the-dog/" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
          <div className="item">
            <div className="work-place">
              <h3 className="place">Kristi Elizabeth, Manhattan Book Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
              <i>"The Way of the Dog</i> was an unexpectedly delightful story. Lessons about love, humans, joy,
                happiness, grace, virtue, and grief are all addressed…
                Overall, this book was a fantastic read with little parables about the meaning of life strewn throughout."
              </p>
              <a href="https://manhattanbookreview.com/product/the-way-of-the-dog/" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
          <div className="item">
            <div className="work-place">
              <h3 className="place">Samy Lax, OnlineBookClub Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
                "If you like wonderful characters, interesting perspectives, and really good lessons on happiness, The Way of the Dog is a book for you."
              </p>
              <a href="https://forums.onlinebookclub.org/viewtopic.php?f=60&t=89434" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
          <div className="item">
            <div className="work-place">
              <h3 className="place">Elizabeth Konkel, Seattle Book Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star-half-o"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
                "<i>The Way of the Dog</i> is a charming read, exploring animals … with a metaphysical and philosophical twist on life and the search for happiness seen through the eyes of a pup who questions everything around him with the help of a variety of quirky animal characters."
              </p>
              <a href="https://seattlebookreview.com/product/the-way-of-the-dog/" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
          <div className="item">
            <div className="work-place">
              <h3 className="place">Daniel Casey, Tulsa Book Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star-o"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
                "Dog lovers will certainly find the story of Eros growing up and experiencing the world an excellent tale to tell children because it encourages them to have equal and empathetic relationships with not just dogs but all animals."
              </p>
              <a href="https://tulsabookreview.com/product/the-way-of-the-dog/" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
          <div className="item">
            <div className="work-place">
              <h3 className="place">Self-Publishing Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star"/> <Icon name="star-o"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
                "An inspiring mix of fiction and self-help, The Way of the Dog is full of humor and heart, with characters that come across as family members rather than just characters on a page, making <b>this original and eye-opening book a must read</b>"
              </p>
              <a href="https://www.selfpublishingreview.com/2018/11/review-the-way-of-the-dog-the-memoirs-of-eros-the-metaphysical-dog-by-eva-a-macdonnell/" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
          <div className="item">
            <div className="work-place">
              <h3 className="place">Jessica Schmidt, Hollywood Book Review</h3>
            </div>
            <div className="job-meta">
              <div className="title"><Icon name="star"/></div>
              <div className="time">2018</div>
            </div>
            <div className="job-desc">
              <p>
                <i>"The Way of the Dog</i> was an unexpectedly delightful story. Lessons about love, humans, joy,
                happiness, grace, virtue, and grief are all addressed…
                Overall, this book was a fantastic read with little parables about the meaning of life strewn throughout."
              </p>
              <a href="https://www.hollywoodbookreviews.com/the-way-of-the-dog/" target="-"><Button>Learn More</Button></a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default rewardsComponent;