import React, { Component } from 'react';
import Icon from 'react-fa';
import Scroll from 'react-scroll';

import HomeBody from './HomeBody';
import Name from './components/NameComponent';
import DateComponent from './components/DateComponent';
import EmailComponent from './components/EmailComponent';
import {EMAIL} from './data/project_metadata'
import profile from './img/profile-image-ab.png'
import SiteMap from './docs/sitemap.xml'

let ScrollLink = Scroll.Link;
let Events = Scroll.Events;
let scrollSpy = Scroll.scrollSpy;

class App extends Component {

    componentDidMount() {
        Events.scrollEvent.register('begin', function() {
        });
        Events.scrollEvent.register('end', function() {
        });
        scrollSpy.update();
    }

    static componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div className="App-header">
                <header className="header">
                    <div className="top-bar container-fluid">
                        <ul className="social list-inline">
                            <li><a href="https://www.instagram.com/erosandeva/"><Icon name="instagram" title="instagram"/></a></li>
                            <li><a href="https://www.facebook.com/Eva-A-MacDonnell-188388832078509/"><Icon name="facebook" title="facebook"/></a></li>
                            <li><a href={`mailto:${EMAIL}`}><Icon name="envelope" title="envelope"/></a></li>
                        </ul>

                    </div>
                    <div className="intro">
                        <div className="container text-center">
                            <img className="profile-image" src={profile} alt="img" />
                                <h1 className="name"><Name/></h1>
                                <div className="title">Author</div>
                                <div className="profile">
                                    <p>
                                        Eva A. MacDonnell is a simple woman who has one gift. The ability to talk to this one dog, Eros, the true author of this book. Here is Eros's biography:
                                    </p>
                                    <p>
                                        Eros lives in the Wild of New Hampshire. Well, not exactly! He lives in a very small house with an elderly couple, minding another dog and a cat, while keeping guard on 20 acres of farmland, chasing deer and wild turkeys away daily. In this dog-world, he is the undisputed alpha-dog, literally. When he is not transcribing his life story to Eva, Eros, now an accomplished philosopher, holds court with squirrels, raccoons, bears, cardinals, and bluebirds, whom come by his house to partake in his wisdom. In between these soliloquies he beats off coyote attacks with one paw and a mighty bark.
                                    </p>
                                    <p>
                                        Eva does live with Eros, and on a good day she does what he asks.
                                    </p>
                                </div>
                        </div>
                    </div>
                    <div className="contact-info">
                        <div className="container text-center">
                            <ul className="list-inline">
                                <li className="email">
                                    <Icon name="envelope" title="email"/>
                                    <EmailComponent/>
                                </li>
                                <li className="website">
                                    <Icon name="globe" title="globe" />
                                    <a href="/" target="_blank">evaamacdonnell.com</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="page-nav-space-holder hidden-xs">
                        <div id="page-nav-wrapper" className="page-nav-wrapper text-center">
                            <div className="container">
                                <ul id="page-nav" className="nav page-nav list-inline">
                                    <li><ScrollLink activeClass="active"
                                              className="scrollto"
                                              to="portfolio-section"
                                              spy={true}
                                              smooth={true}
                                              duration={500}>Books</ScrollLink></li>
                                    <li><ScrollLink activeClass="active"
                                                    className="scrollto"
                                                    to="testimonials-section"
                                                    spy={true}
                                                    smooth={true}
                                                    duration={500}>Featured Reviews</ScrollLink></li>
                                  <li><ScrollLink activeClass="active"
                                                  className="scrollto"
                                                  to="experiences-section"
                                                  spy={true}
                                                  smooth={true}
                                                  duration={500}>Awards & Reviews</ScrollLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>

                <HomeBody name="body content"/>

                <footer className="footer text-center">
                    <div className="container">
                        <small className="copyright">Copyright &copy; <DateComponent type="year"/>
                             Sunset Hill Trust | <a href={SiteMap}>Site map</a>
                        </small>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
